
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import { Head, Steps, Split, Invert, SplitRight, themes } from 'mdx-deck'
import { FaTwitter } from 'react-icons/fa'
import MultiCodeBlock, { CodeBlockProvider } from './components'
import TwitterHandle from './twitter-handle'
import end from './end.png'
import flavors from './flavors.png'
import plan from './plan.png'
import newPlan from './new-plan.png'
import alchemy from './alchemy.png'
import title from './title.png'
import babel from './babel.png'
import prettier from './prettier.png'
import disappointed from './disappointed.jpg'
import messi from './messi.jpg'
import guide from './guide.gif'
import rainbow from './rainbow.gif'
export const theme = { ...themes.condensed,
  ...themes.prism
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};
const Footer = makeShortcode("Footer");
const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Head mdxType="Head">
  <title>Code Block Alchemy</title>
  <link rel="icon" href={alchemy} />
    </Head>
    <Footer mdxType="Footer">
      <h4>
  <TwitterHandle mdxType="TwitterHandle" />
      </h4>
    </Footer>
    <img src={alchemy} style={{
      height: 100,
      marginBottom: '2em'
    }} />
    <img src={title} style={{
      height: 120,
      marginBottom: '1em'
    }} />
    <h2>{`Building multi-language code samples`}</h2>
    <p>{`Trevor Blades, web developer`}</p>
    <hr></hr>
    <Invert mdxType="Invert">
      <h1>{`Developer experience`}</h1>
      <img src={guide} style={{
        maxHeight: 400
      }} />
      <p>{`We want to create awesome documentation`}</p>
    </Invert>
    <hr></hr>
    <h1>{`Code samples are important`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Help developers understand complex ideas`}</li>
        <li parentName="ul">{`See what working code should look like`}</li>
        <li parentName="ul">{`Written using tools that developers are using`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`TypeScript is popular`}</h1>
    <img src={flavors} />
    <p>{`Developers want to see code samples written in TypeScript`}</p>
    <hr></hr>
    <h1>{`Our code samples`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: "http://localhost:4000/"
});
`}</code></pre>
    <p>{`Not written in TypeScript`}</p>
    <hr></hr>
    <h1>{`The challenge`}</h1>
    <Steps mdxType="Steps">
      <ol>
        <li parentName="ol">{`Demonstrate how to use Apollo with TypeScript`}</li>
        <li parentName="ol">{`Preserve JavaScript examples`}</li>
        <li parentName="ol">{`Avoid writing the same thing twice`}</li>
      </ol>
    </Steps>
    <hr></hr>
    <h1>{`Avoid writing the same thing twice`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`TypeScript compiles to JavaScript`}</li>
        <li parentName="ul">{`Code samples are written in Markdown`}</li>
        <li parentName="ul">{`We use Remark to process the Markdown content`}</li>
        <li parentName="ul">{`Remark plugins allow us to change Markdown using Node.js`}</li>
        <li parentName="ul">{`The TypeScript compiler (`}<inlineCode parentName="li">{`tsc`}</inlineCode>{`) runs in Node.js`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`Remark plugins 101`}</h1>
    <ol>
      <li parentName="ol">{`"Visit" nodes in the Markdown AST by type (ie. `}<inlineCode parentName="li">{`pre`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`Perform some work`}</li>
      <li parentName="ol">{`Mutate the Markdown AST`}</li>
    </ol>
    <hr></hr>
    <h1>{`The plan`}</h1>
    <img src={plan} style={{
      maxHeight: 250
    }} />
    <ul>
      <li parentName="ul">{`Write code samples in TypeScript`}</li>
      <li parentName="ul">{`Compile TypeScript to JavaScript using `}<inlineCode parentName="li">{`tsc`}</inlineCode></li>
      <li parentName="ul">{`Insert new code block into the page`}</li>
      <li parentName="ul">{`Toggle between TypeScript and JavaScript`}</li>
    </ul>
    <hr></hr>
    <div style={{
      fontSize: '0.7em'
    }}>
      <pre><code parentName="pre" {...{
          "className": "language-ts"
        }}>{`import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject
} from "@apollo/client";

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache(),
  uri: "http://localhost:4000/"
});
`}</code></pre>
    </div>
    <div style={{
      fontSize: '0.7em'
    }}>
      <Steps mdxType="Steps">
        <div>
          <pre><code parentName="pre" {...{
              "className": "language-js"
            }}>{`import { ApolloClient, InMemoryCache } from '@apollo/client';
const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: "http://localhost:4000/"
});
`}</code></pre>
        </div>
      </Steps>
    </div>
    <hr></hr>
    <img src={messi} style={{
      maxHeight: 500
    }} />
    <Steps mdxType="Steps">
      <p>{`But what about a more complex example?`}</p>
    </Steps>
    <hr></hr>
    <div style={{
      fontSize: '0.4em'
    }}>
      <pre><code parentName="pre" {...{
          "className": "language-tsx"
        }}>{`interface ProfileProps extends RouteComponentProps {}

const Profile: React.FC<ProfileProps> = () => {
  const { data, loading, error } = useQuery<GetMyTripsTypes.GetMyTrips, any>(
    GET_MY_TRIPS,
    { fetchPolicy: "network-only" }
  );
  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (data === undefined) return <p>ERROR</p>;

  return (
    <Fragment>
      <Header>My Trips</Header>
      {data.me && data.me.trips.length ? (
        data.me.trips.map((launch: any) => (
          <LaunchTile key={launch.id} launch={launch} />
        ))
      ) : (
        <p>You haven't booked any trips</p>
      )}
    </Fragment>
  );
}

export default Profile;
`}</code></pre>
    </div>
    <div style={{
      fontSize: '0.4em'
    }}>
  
      <Steps mdxType="Steps">
        <div>
          <pre><code parentName="pre" {...{
              "className": "language-jsx"
            }}>{`const Profile = () => {
    const { data, loading, error } = useQuery(GET_MY_TRIPS, { fetchPolicy: "network-only" });
    if (loading)
        return <Loading />;
    if (error)
        return <p>ERROR: {error.message}</p>;
    if (data === undefined)
        return <p>ERROR</p>;
    return (<Fragment>
      <Header>My Trips</Header>
      {data.me && data.me.trips.length ? (data.me.trips.map((launch) => (<LaunchTile key={launch.id} launch={launch}/>))) : (<p>You haven't booked any trips</p>)}
    </Fragment>);
};
export default Profile;
`}</code></pre>
        </div>
      </Steps>
    </div>
    <hr></hr>
    <p>{`The TypeScript compiler doesn't care about your whitespace`}</p>
    <ul>
      <li parentName="ul">{`✅ It is JavaScript`}</li>
      <li parentName="ul">{`👎 Empty lines are removed`}</li>
      <li parentName="ul">{`👎 Spaces are converted to tabs`}</li>
      <li parentName="ul">{`👎 New line breaks are introduced`}</li>
    </ul>
    <Steps mdxType="Steps">
  
      <div>
  <img src={disappointed} style={{
          maxHeight: 400
        }} />
      </div>
    </Steps>
    <hr></hr>
    <h1>{`Compiler`}</h1>
    <ul>
      <li parentName="ul">{`Takes source code written in one language`}</li>
      <li parentName="ul">{`Outputs code in another language`}</li>
      <li parentName="ul">{`Typically, compilers like `}<inlineCode parentName="li">{`tsc`}</inlineCode>{` or `}<inlineCode parentName="li">{`gcc`}</inlineCode>{` produce code that is meant to be run, `}<em parentName="li">{`not`}</em>{` read`}</li>
    </ul>
    <hr></hr>
    <h1>{`Transpiler`}</h1>
    <ul>
      <li parentName="ul">{`Known as a "source-to-source" compiler`}</li>
      <li parentName="ul">{`Output is usually understandable by a human`}</li>
      <li parentName="ul">{`Output still has to go through a compiler to be run`}</li>
    </ul>
    <hr></hr>
    <img src={babel} style={{
      height: 200
    }} />
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Can `}<em parentName="li">{`transpile`}</em>{` TypeScript using `}<inlineCode parentName="li">{`@babel/preset-typescript`}</inlineCode></li>
        <li parentName="ul">{`Doesn't have opinions about formatting`}</li>
        <li parentName="ul">{`Keeps empty lines using `}<inlineCode parentName="li">{`retainLines`}</inlineCode>{` option`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <Split mdxType="Split">
      <div style={{
        marginRight: '1em'
      }}>
        <h2>{`TypeScript`}</h2>
        <div style={{
          fontSize: '0.6em'
        }}>
          <pre><code parentName="pre" {...{
              "className": "language-tsx"
            }}>{`interface ProfileProps extends RouteComponentProps {}

const Profile: React.FC<ProfileProps> = () => {
  const { data, loading, error } = useQuery<GetMyTripsTypes.GetMyTrips, any>(
    GET_MY_TRIPS,
    { fetchPolicy: "network-only" }
  );
  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (data === undefined) return <p>ERROR</p>;

  return (
    <Fragment>
      <Header>My Trips</Header>
      {data.me && data.me.trips.length ? (
        data.me.trips.map((launch: any) => (
          <LaunchTile key={launch.id} launch={launch} />
        ))
      ) : (
        <p>You haven't booked any trips</p>
      )}
    </Fragment>
  );
}

export default Profile;
`}</code></pre>
        </div>
      </div>
      <div style={{
        marginLeft: '1em'
      }}>
        <h2>{`Babel`}</h2>
        <div style={{
          fontSize: '0.6em'
        }}>
          <pre><code parentName="pre" {...{
              "className": "language-jsx"
            }}>{`

const Profile = () => {
  const { data, loading, error } = useQuery(
  GET_MY_TRIPS,
  { fetchPolicy: "network-only" });

  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (data === undefined) return <p>ERROR</p>;

  return (
    <Fragment>
      <Header>My Trips</Header>
      {data.me && data.me.trips.length ?
      data.me.trips.map((launch) =>
      <LaunchTile key={launch.id} launch={launch} />) :


      <p>You haven't booked any trips</p>}

    </Fragment>);

};

export default Profile;
`}</code></pre>
        </div>
      </div>
    </Split>
    <hr></hr>
    <h1>{`Almost there`}</h1>
    <ul>
      <li parentName="ul">{`✅ Retained same phrasing and whitespace`}</li>
      <li parentName="ul">{`👎 Empty space where TypeScript code used to be`}</li>
      <li parentName="ul">{`👎 Incorrect indentation and janky formatting in areas`}</li>
    </ul>
    <hr></hr>
    <SplitRight mdxType="SplitRight">
      <div style={{
        fontSize: '0.6em'
      }}>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`const Profile = () => {
  const { data, loading, error } = useQuery(
    GET_MY_TRIPS,
    { fetchPolicy: "network-only" }
  );
  if (loading) return <Loading />;
  if (error) return <p>ERROR: {error.message}</p>;
  if (data === undefined) return <p>ERROR</p>;

  return (
    <Fragment>
      <Header>My Trips</Header>
      {data.me && data.me.trips.length ? (
        data.me.trips.map(launch => (
          <LaunchTile key={launch.id} launch={launch} />
        ))
      ) : (
        <p>You haven't booked any trips</p>
      )}
    </Fragment>
  );
};

export default Profile;
`}</code></pre>
      </div>
      <img src={prettier} style={{
        height: 250
      }} />
      <p>{`✅ Fixes indentation and empty space`}</p>
    </SplitRight>
    <hr></hr>
    <h1>{`The new plan`}</h1>
    <img style={{
      maxHeight: 250
    }} src={newPlan} />
    <ul>
      <li parentName="ul">{`Write code samples in TypeScript`}</li>
      <li parentName="ul"><del parentName="li">{`Compile TypeScript to JavaScript using `}<inlineCode parentName="del">{`tsc`}</inlineCode></del></li>
      <li parentName="ul">{`Transpile TypeScript to JavaScript using Babel`}</li>
      <li parentName="ul">{`✨ Fix formatting using Prettier ✨`}</li>
      <li parentName="ul">{`Insert new code block into the page`}</li>
      <li parentName="ul">{`Toggle between TypeScript and JavaScript`}</li>
    </ul>
    <hr></hr>
    <h1>{`Insert new code block into the page`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`Lorem ipsum dolor sit amet, consectetur adipiscing
elit, sed do eiusmod tempor incididunt ut labore
et dolore magna aliqua.

\`\`\`ts
// TypeScript code block
\`\`\`

<-- JS code block inserted here
`}</code></pre>
    <p>{`That's cool, but not `}<em parentName="p">{`every`}</em>{` TypeScript code block should be transpiled`}</p>
    <hr></hr>
    <h1>{`Using a wrapper`}</h1>
    <p><em parentName="p">{`Only`}</em>{` transpile blocks where the previous node is `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` and the next node is `}<inlineCode parentName="p">{`</div>`}</inlineCode></p>
    <div style={{
      fontSize: '0.6em'
    }}>
      <pre><code parentName="pre" {...{
          "className": "language-md"
        }}>{`Lorem ipsum dolor sit amet, consectetur adipiscing
elit, sed do eiusmod tempor incididunt ut labore
et dolore magna aliqua.

<div>

\`\`\`ts
// This code block gets transpiled
\`\`\`

</div>

\`\`\`ts
// This code block does not get transpiled
\`\`\`
`}</code></pre>
    </div>
    <hr></hr>
    <h1>{`Toggle between TypeScript and JavaScript`}</h1>
    <div style={{
      fontSize: '0.6em'
    }}>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`import React, { useState } from 'react';

export function MultiCodeBlock(props) {
  const [index, setIndex] = useState(0);
  return (
    <>
      Select a language:
      <select
        value={index}
        onChange={event => {
          setIndex(event.target.value);
        }}
      >
        {props.children.map((child, index) => (
          <option key={index} value={index}>
            {child.props.children.props.className.replace('language-', '')}
          </option>
        ))}
      </select>
      {props.children[index]}
    </>
  );
}
`}</code></pre>
    </div>
    <hr></hr>
    <Split mdxType="Split">
      <div style={{
        marginRight: '1em'
      }}>
        <h2>{`MDX`}</h2>
        <div style={{
          fontSize: '0.6em'
        }}>
          <pre><code parentName="pre" {...{
              "className": "language-md"
            }}>{`import { MultiCodeBlock } from './components'

<MultiCodeBlock>

\`\`\`ts
function add(a: number, b: number): number {
  return a + b;
}

const sum: number = add(6, 4); // 10
\`\`\`

</MultiCodeBlock>
`}</code></pre>
        </div>
      </div>
      <div style={{
        marginLeft: '1em'
      }}>
        <h2>{`Output`}</h2>
        <div style={{
          width: 640,
          fontSize: '0.6em',
          textAlign: 'left'
        }}>
          <CodeBlockProvider mdxType="CodeBlockProvider">
            <MultiCodeBlock mdxType="MultiCodeBlock">
              <pre><code parentName="pre" {...{
                  "className": "language-ts"
                }}>{`function add(a: number, b: number): number {
  return a + b;
}

const sum: number = add(6, 4); // 10
`}</code></pre>
              <pre><code parentName="pre" {...{
                  "className": "language-js"
                }}>{`function add(a, b) {
  return a + b;
}

const sum = add(6, 4); // 10
`}</code></pre>
            </MultiCodeBlock>
          </CodeBlockProvider>
        </div>
      </div>
    </Split>
    <hr></hr>
    <h1>{`Works for one, not for many`}</h1>
    <ul>
      <li parentName="ul">{`Each code block manages its own state`}</li>
      <li parentName="ul">{`Reader is required to change each code block one at a time`}</li>
      <li parentName="ul">{`All code blocks should remain in sync`}</li>
    </ul>
    <hr></hr>
    <h1>{`Context to the rescue`}</h1>
    <div style={{
      fontSize: '0.7em'
    }}>
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`import React, { createContext, useState } from 'react';

const CodeBlockContext = createContext();

export function CodeBlockProvider(props) {
  const state = useState(0);
  return (
    <CodeBlockContext.Provider value={state}>
      {props.children}
    </CodeBlockContext.Provider>
  );
}
`}</code></pre>
    </div>
    <div style={{
      fontSize: '0.7em'
    }}>
      <pre><code parentName="pre" {...{
          "className": "language-diff"
        }}>{`export function MultiCodeBlock(props) {
- const [index, setIndex] = useState(0);
+ const [index, setIndex] = useContext(CodeBlockContext);
  // rest of component
}
`}</code></pre>
    </div>
    <hr></hr>
    <Split mdxType="Split">
      <div style={{
        marginRight: '1em'
      }}>
        <h2>{`MDX`}</h2>
        <div style={{
          width: 400,
          fontSize: '0.6em'
        }}>
          <pre><code parentName="pre" {...{
              "className": "language-md"
            }}>{`import {
  MultiCodeBlock,
  CodeBlockProvider
} from './components'

<CodeBlockProvider>

<MultiCodeBlock>

\`\`\`ts
const foo: number = 123;
\`\`\`

</MultiCodeBlock>

<MultiCodeBlock>

\`\`\`ts
const bar: string = 'abc';
\`\`\`

</MultiCodeBlock>

</CodeBlockProvider>
`}</code></pre>
        </div>
      </div>
      <div style={{
        marginLeft: '1em'
      }}>
        <h2>{`Output`}</h2>
        <div style={{
          width: 400,
          fontSize: '0.6em',
          textAlign: 'left'
        }}>
          <CodeBlockProvider mdxType="CodeBlockProvider">
            <MultiCodeBlock mdxType="MultiCodeBlock">
              <pre><code parentName="pre" {...{
                  "className": "language-ts"
                }}>{`const foo: number = 123;
`}</code></pre>
              <pre><code parentName="pre" {...{
                  "className": "language-js"
                }}>{`const foo = 123;
`}</code></pre>
            </MultiCodeBlock>
            <br />
            <MultiCodeBlock mdxType="MultiCodeBlock">
              <pre><code parentName="pre" {...{
                  "className": "language-ts"
                }}>{`const bar: string = 'abc';
`}</code></pre>
              <pre><code parentName="pre" {...{
                  "className": "language-js"
                }}>{`const bar = 'abc';
`}</code></pre>
            </MultiCodeBlock>
          </CodeBlockProvider>
        </div>
      </div>
    </Split>
    <hr></hr>
    <h1>{`Tools used`}</h1>
    <ul>
      <li parentName="ul">{`Remark: Read/edit the Markdown AST`}</li>
      <li parentName="ul">{`Babel: Transpile the TypeScript code`}</li>
      <li parentName="ul">{`Prettier: Format the output`}</li>
      <li parentName="ul">{`MDX: Use React components in Markdown`}</li>
    </ul>
    <hr></hr>
    <Invert mdxType="Invert">
      <h1>{`Developer experience`}</h1>
      <img src={rainbow} style={{
        maxHeight: 400
      }} />
      <p>{`We create awesome documentation`}</p>
    </Invert>
    <hr></hr>
    <img src={end} style={{
      maxHeight: 100,
      marginBottom: '1.5em'
    }} />
    <ul>
      <li parentName="ul">{`MultiCodeBlocks in the wild: `}<a parentName="li" {...{
          "href": "https://bit.ly/3eK5Pxh"
        }}>{`https://bit.ly/3eK5Pxh`}</a></li>
      <li parentName="ul">{`Remark plugin: `}<a parentName="li" {...{
          "href": "https://github.com/trevorblades/remark-typescript"
        }}>{`https://github.com/trevorblades/remark-typescript`}</a></li>
      <li parentName="ul">{`Compiler vs. transpiler: `}<a parentName="li" {...{
          "href": "https://stackoverflow.com/a/44932758/8190832"
        }}>{`https://stackoverflow.com/a/44932758/8190832`}</a></li>
      <li parentName="ul">{`Slides: `}<a parentName="li" {...{
          "href": "https://alchemy.trevorblades.com"
        }}>{`https://alchemy.trevorblades.com`}</a></li>
    </ul>
    <h3>
  <TwitterHandle mdxType="TwitterHandle" />
    </h3>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;